import React from "react";
import Logo from "../Logo/Logo";
import Button from "../Button/Button";

const Navigation = ({ color }) => {
  return (
    <div className={"container Navigation"}>
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between mb-4">
            <Logo/>
            <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                <li className="nav-item"><a href={'#home'} className="nav-link fw-bold link-dark">Home</a></li>
                <li className="nav-item"><a href={'#about'} className="nav-link fw-bold link-dark">About</a></li>
                <li className="nav-item"><a href={'#process'} className="nav-link fw-bold link-dark">Our Process</a></li>
                <li className="nav-item"><a href={'#testimonials'} className="nav-link fw-bold link-dark">Testimonials</a></li>
                <li className="nav-item"><a href={'#contact'} className="nav-link fw-bold link-dark">Contact</a></li>
            </ul>
            <div className="text-end">
               <Button text={'Get Started'} color={color} url={'#contact'}/>
            </div>
        </header>
    </div>
  );
};
export default Navigation;
