import React from "react";

const Card = ({ child, text, background }) => {
  return (
      <div className={`card ${background}`}>
          {child}
          {text && (
              <div className="card-body">
                  <p className="card-text">{text}</p>
              </div>
          )}

      </div>
  )
}
export default Card;
