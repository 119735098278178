import './styles/bootstrap.css';
import './styles/index.scss';
import Main from "./pages/main";


function App() {
  return (
    <div className="App">
        <Main/>
    </div>
  );
}

export default App;
