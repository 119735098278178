import React, { useState } from "react";
import axios from 'axios';
import FormField from "../FormField/FormField";
import Button from "../Button/Button";

const Form = ({ props }) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });

  const resetForm = () => {
    setState({ name: "", email: "", phone: "", comment: "" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios({
      url: "https://www.brighterdaycashback.com/send/contactform.php",
      method: "POST",
      data: state,
    })
      .then((response) => {
        if (response.data.status === 'success') {
          resetForm();
        } else if (response.status === 'fail') {
        }
      });
  };
  return (
    <form name="contact-form" method="POST" onSubmit={(e) => handleSubmit(e)}>
      <FormField
        id={"name"}
        type={"input"}
        label={"Name"}
        value={state.name}
        onChange={(e) => setState({...state, name: e.target.value })}
      />
      <FormField
        id={"email"}
        type={"email"}
        label={"Email"}
        value={state.email}
        onChange={(e) => setState({...state, email: e.target.value })}
      />
      <FormField
        id={"phone"}
        type={"input"}
        label={"Phone"}
        value={state.phone}
        onChange={(e) => setState({...state, phone: e.target.value })}
      />
      <FormField
        id={"comment"}
        type={"input"}
        label={"Comments"}
        value={state.comment}
        onChange={(e) => setState({...state, comment: e.target.value })}
      />
      <Button color={"button-yellow"} size={"btn-md"} text={"Send Email"} type={'submit'} />
    </form>
  );
};
export default Form;
