import Navigation from "../component/Navigation/Navigation";
import Hero from "../component/Hero/Hero";
import HeroCTA from "../component/HeroCTA/HeroCTA";
import ContentBlock from "../component/ContentBlock/ContentBlock";
import FeaturesColumn from "../component/FeaturesColumn/FeaturesColumn";
import FeaturesIcon from "../component/FeaturesIcon/FeaturesIcon";
import Footer from "../component/Footer/Footer";
import imageMain from "../images/photo1.png";
import image2 from "../images/photo2.png";
import image3 from "../images/photo3.png";
import agreement from "../images/2021serviceagreement.pdf";
import React from "react";
import ContentPortion from "../component/ContentPortion/ContentPortion";
import Reviews from "../component/Reviews/reviews";
import upsImage from "../images/upsstore.jpg";
import Button from "../component/Button/Button";
import Card from "../component/Card/Card";
function Main() {
  return (
    <div className="Main">
      <div className="col-lg-12 mx-auto p-3 py-md-4">
        <Navigation color={"btn-dark"} />
      </div>
      <Hero
        imageCol={"col-lg-6"}
        textCol={"col-lg-6"}
        flexAlign={"flex-lg-row-reverse "}
        textSize={"display-5"}
        buttonText={"How It Works"}
        image={imageMain}
        lead={
          "We are a company that offers a service , " +
          "to help Unclaimed Property owners claim and " +
          "receive their unclaimed money , from the State of Michigan / government."
        }
        titleHead={"Hello, Welcome to"}
        titleSub={"Brighter Day Cashback "}
      />
      <div id={"home"}>
        <HeroCTA
          title={"Brighter Day Cashback"}
          lead={
            "is here to help you \n" +
            "claim and recover the money you are missing.\n" +
            "Let Brighter Day Cashback get the money \n" +
            "that is legally and rightfully yours returned to your mailbox."
          }
        />
      </div>

      <div className={"ContentBlock container col-xxl-8 py-4"}>
        <div className="row align-items-md-stretch">
          <div className="col-md-6">
            <ContentBlock
              background={"bg-offwhite"}
              title={"Many people in the millions..."}
              text={
                "Have money going unclaimed in the State of Michigan / government banking accounts . Most dont believe or even heard of unclaimed money , you can possibly be one of these people . "
              }
            ></ContentBlock>
          </div>
          <div className="col-md-6">
            <ContentBlock
              background={"bg-offwhite"}
              title={"Many people are unaware of..."}
              text={
                "Unclaimed property that the State of Michigan / governments is holding . We have included news video clips to show unclaimed property is very real and big problem for many people , who are missing out on huge amounts of their own money . "
              }
            />
          </div>
        </div>
      </div>

      <Hero
        imageCol={"col-lg-4"}
        textCol={"col-lg-8"}
        textSize={"h4"}
        image={image2}
        content1={
          "Comes from Banks , policies , insurance comapnies and other businesses , etc . These entities are required to return money that belongs to their customers from previous envolvement to the State of Michigan /  government .  To wait to be claimed by the rightful property owner to withdraw from the State of Michigan."
        }
        content2={
          "While your unclaimed money sits in the State / government accounts , their collecting interest while in possession of your lawful money . Which is why they dont try very hard to return your money . Brighter Day Cashback Company search through the State of Michigan government accounts to return your unclaimed funds ."
        }
        content3={
          "There is no upfront cost to you .  We only get paid a small percentage from you , if we get your money returned to your mailbox. As a finders fee and service fee for our hard diligent work  , to recover and deliver your legal rightful funds to you ."
        }
        titleHead={"Unclaimed funds"}
      ></Hero>
      <div className={"col-md-6 m-auto"}>
        <HeroCTA
          colSize={"col-lg-6"}
          title={"Brighter Day Cashback"}
          lead={
            "Is a company to help people recover their unclaimed funds .\n" +
            "Questions?."
          }
          url={"#contact"}
          buttonPrimaryText={"Contact Us"}
        />
      </div>

      <div id={"about"}>
        <Hero
          imageCol={"col-lg-4"}
          textCol={"col-lg-8"}
          textSize={"h4"}
          image={image3}
          content1={
            'Brighter Day Cashback aim to make as many people as possible day a lot "Brighter" One of the things that makes the "Day" and life better is getting "Cashback"'
          }
          content2={
            "Our company Brighter Day Cashback has become recovery specialist , to unite people with their unclaimed cash . In many cases , most people never know about unclaimed property . Also ,  the fact many people possibly have money waiting to be claimed in the State of Michigan government account ."
          }
          content3={
            "Once our company learned of this problem , we want to take action . To unite as many people as we can with their lawful property . As we understand many people are in need , and their rightful cash can help themselves and family ." +
            "Brighter Day Cashback is a unclaimed funds recovery service . We work hard and deligently to recover your unclaimed funds ( cash property ) . Our mission is to find the unclaimed funds and the property owners .  Also to return the cash to the correct property owner(s) . Let us help to recover your unclaimed check to be delivered to your mailbox . "
          }
          titleHead={"About Us"}
        />
      </div>
      <div id={"process"}>
        <FeaturesIcon />
      </div>
      <div className={"container text-center mb-5"}>
        <Button
          color={"btn-dark"}
          size={"btn-lg"}
          url={agreement}
          text={"View Service Agreement"}
        />
      </div>
      <div id={"testimonials"}>
        <Reviews />
      </div>

      <div className={"container py-5"}>
        <div className={"row justify-content-md-center"}>
          <p className="h4 fw-bold text-center mb-5">News Clippings</p>
          <div className={"col-md-auto"}>
            <Card
              child={
                <iframe
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/TVgj3P0FeQ8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              }
            />
          </div>
          <div className={"col-md-auto"}>
            <Card
              child={
                <iframe
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/e-ZJWoJEIZA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              }
            />
          </div>
          <div className={"col-md-auto"}>
            <Card
              child={
                <iframe
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/aliMjf3zpiA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              }
            />
          </div>
        </div>
      </div>
      <div className={"col-md-12 m-auto"}>
        <HeroCTA
          colSize={"col-lg-12"}
          title={"The UPS Store"}
          lead={"Brighter Day Cash Back official public notary."}
          image={upsImage}
        />
      </div>

      <div id={"contact"}>
        <Footer />
      </div>
    </div>
  );
}

export default Main;
