import React from "react";
import logo from "../../images/logo.png";

const Logo = ({ props }) => {
  return (
    <div className={"Logo"}>
      <a
        href="/"
        className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
          <img
              src={logo}
              className="d-block mx-lg-auto"
              loading="lazy"/>

          <span className="ms-lg-4 fs-4 fw-bold">BrighterDayCashBack</span>
      </a>
    </div>
  );
};
//<img src={logo} className="App-logo" alt="logo" width={'50px'}/>
export default Logo;
