import React from "react";
import Form from "../Form/Form";
import ContentBlock from "../ContentBlock/ContentBlock";
import cashapp from "../../images/pay-cashapp.png"
import zelle from "../../images/pay-zelle.png"

const Footer = ({ props }) => {
  return (
    <footer className="footer mt-auto py-5">
      <div className={"container text-white"}>
        <div className="row align-ems-md-stretch">
          <div className={"col-md-6"}>
              <div className={'footer_content'}>
                  <h4 className={'footer_title mb-5'}>Contact Us</h4>
                  <p className={'mb-2'}>Brighter Day Cashback</p>
                  <p className={'mb-2'}>David Perkins</p>
                  <p className={'mb-2'}>313.314.6381</p>
                  <p className={'mb-5 fw-bold'}>BrighterDayCashback@gmail.com</p>
                  <p className={'mb-4'}>Meetings by appointment</p>
                  <p className={'mb-2 fw-bold'}>Hello clients of Brighter Day Cashback</p>
                  <p>Please leave a review and receive $25.00 or $25 gift card</p>
              </div>
              <div className={'row mt-5'}>
                  <div className={'col-md-1'}>
                      <img
                          src={cashapp}
                          className="d-block img-fluid"
                          loading="lazy"
                      />
                  </div>
                  <div className={'col-md-2'}>
                      <img
                          src={zelle}
                          className="d-block img-fluid"
                          loading="lazy"
                      />
                  </div>

              </div>
          </div>
          <div className={"col-md-6"}>
            <Form />
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
