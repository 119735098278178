import React from "react";
import Button from "../Button/Button";

const HeroCTA = ({ title, lead, buttonPrimaryText, buttonSecondaryText, colSize, url, image }) => {
  return (
    <div className={"HeroCTA"}>
      <div className="px-4 py-4 my-2 text-center">
        <p className="h2 fw-bold">{title}</p>
        <div className={`col-lg-3 mx-auto ${colSize}`}>
          <p className="lead mb-2">{lead}</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            {buttonPrimaryText && (
                <Button color={"button-yellow"}
                        size={"btn-lg"}
                        url={url}
                        text={buttonPrimaryText} />
            )}
            {buttonSecondaryText && (
                <Button color={"button-yellow"}
                        size={"btn-lg"}
                        url={url}
                        text={buttonSecondaryText} />
            )}
          </div>
        </div>
        {image && (
            <div className="overflow-hidden" style={{maxHeight: '30vh'}}>
              <div className="container px-5">
                <img src={image} className="img-fluid border rounded-3 shadow-lg mb-4"
                     width="900" height="700" loading="lazy"/>
              </div>
            </div>
        )}

      </div>
    </div>
  );
};
export default HeroCTA;
