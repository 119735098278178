import React from 'react';
import icons from '../../images/icons/alarm.svg';
import HeroCTA from "../HeroCTA/HeroCTA";

const FeaturesIcon = ({props}) => {
    return (<div className={'FeaturesIcon'}>
        <div className="container py-5" id="icon-grid">
            <h2 className="pb-2 text-center">Our Process</h2>

            <div className="row g-5 py-5 lead_content">
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">1</button>
                    <div>
                        <h4 className="fw-bold mb-0 ">Brighter Day Cashback </h4>
                        <p className="justify-content-center">searches through the state of michigan unclaimed funds list available to the public.</p>
                    </div>
                </div>
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">2</button>
                    <div>
                        <h4 className="fw-bold mb-0">Our company find, names, city </h4>
                        <p>which business your unclaimed $$$ is to be returned to you from, the claim account number and the amount of $$$ our company can lawfully recover to your mailbox.</p>
                    </div>
                </div>
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">3</button>
                    <div>
                        <h4 className="fw-bold mb-0">We then search</h4>
                        <p>for your name and phone number online , from public online search sites</p>
                    </div>
                </div>
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">4</button>
                    <div>
                        <h4 className="fw-bold mb-0">Next we contact</h4>
                        <p>the person rightfully owed unclaimed funds by email, text , or call . To ask if the unclaimed owner would like our company assistance or help to recover their unclaimed money .</p>
                    </div>
                </div>

            </div>
            <div className={'hero_bg2 col-md-5 m-auto '}>
                <HeroCTA colSize={'col-lg-10'} lead={
                    "If client says \"YES\" to our Brighter Day Cashback service, \n" +
                    "for assistance  to recover the unclaimed funds to be returned to the proper owner "}
                />
            </div>

            <div className="row g-5 py-5 lead_content">
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">1</button>
                    <div>
                        <h4 className="fw-bold mb-0">Our company will set an appointment</h4>
                        <p>to meet with the owner , to move forward with the process of
                            recovering your unclaimed funds for you . </p>
                    </div>
                </div>
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">2</button>
                    <div>
                        <h4 className="fw-bold mb-0">We would meet</h4>
                        <p>to sign a service agreement document contract to ensure great , smooth,  trusting, honest , and legal business deal .</p>
                    </div>
                </div>
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">3</button>
                    <div>
                        <h4 className="fw-bold mb-0">We will have the service agreement</h4>
                        <p>signed and stamped by the public notary  so everything will be legal and both of our parties are in
                            agreement and to ensure nothing illegal takes place .</p>
                    </div>
                </div>
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">4</button>
                    <div>
                        <h4 className="fw-bold mb-0">Additional information</h4>
                        <p>needed requested by the State Of Michigan
                        </p>
                        <ul>
                            <li className={'p-0'}>Notarized service agreement signed and stamped by public Notary</li>
                            <li className={'p-0'}>Copy of State Identification Card ( copy of our company owner ID also )</li>
                            <li className={'p-0'}>Copy of Social Security Card </li>
                        </ul>

                    </div>
                </div>
                <div className="feature col-md-3">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">5</button>
                    <div>
                        <h4 className="fw-bold mb-0">The information - documents</h4>
                        <p>will be sent to the State Of Michigan Treasury from our Company on your behalf,  to claim and recover the unclaimed money  to be sent in full directly to your mailbox.
                        </p>
                        <ul>
                            <li className={'p-0'}>The unclaimed money sent to you will depend on the verification of the State Of Michgan Treasury to entitle you the unclaimed funds</li>
                            <li className={'p-0'}>After verification you are the rightful recipient of the unclaimed money , you will be receiving a check in 6 to 8 weeks.</li>
                        </ul>
                    </div>
                </div>
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">6</button>
                    <div>
                        <h4 className="fw-bold mb-0">While awaiting</h4>
                        <p>your check to arrive to your mailbox . Brighter Day
                            Cashback will send an invoice of
                            payment ,  for the finder fee and recovery service provided . Payment is to be expected , only after you have received your unclaimed money check .
                        </p>
                    </div>
                </div>
                <div className="feature col-md-3 ">
                    <button type="button" className="btn button-yellow fw-bold mb-3 ">7</button>
                    <div>
                        <h4 className="fw-bold mb-0">Brighter Day Cashback</h4>
                        <p>will also like to ask if you leave a client review.
                            If you choose to leave a review you will recieve a $25.00 thank you gift .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
export default FeaturesIcon;
