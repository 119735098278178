import React from "react";

const FormField = ({ id, type, label, placeholder, value, onChange }) => {
  return (
    <div className="mb-3 ">
      <label htmlFor={id} className={'form_label mb-3'}>{label}</label>
      <input
        type={type}
        className="form-control border-0 form_field"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
export default FormField;
