import React from "react";
import Button from "../Button/Button";
import ContentPortion from "../ContentPortion/ContentPortion";

const Hero = ({ image, titleHead, titleSub, lead, buttonText, content1,content2,content3,textSize, flexAlign, imageCol, textCol }) => {
  return (
    <div className={"Hero"}>
      <div className="container col-xxl-12 px-4 py-2">
        <div className={`row ${flexAlign} align-items-center g-5 py-5`}>
          <div className={`col-10 col-sm-8 ${imageCol}`}>
            <img
              src={image}
              className="d-block mx-lg-auto img-fluid"
              loading="lazy"
            />
          </div>
          <div className={textCol}>
            <p className={`${textSize} fw-bold lh-1 mb-3`}>{titleHead}</p>
            {titleSub && (
              <p className="display-5 fw-bold lh-1 mb-3">{titleSub}</p>
            )}
            {lead && (<p className="lead">{lead}</p>)}
            {content1 && (
                <ContentPortion text={content1}/>
            )}
            {content2 && (
                <ContentPortion text={content2}/>
            )}
            {content3 && (
                <ContentPortion text={content3}/>
            )}
            {buttonText && (
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">

                <Button
                  color={"button-yellow"}
                  size={"btn-lg"}
                  text={buttonText}
                  url={'#process'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
