import React from "react";

const ContentBlock = ({children, title, text, buttonText, background }) => {
  return (
    <div className={`h-100 p-5 text-black ${background} rounded-3`}>
      <h3>{title}</h3>
      <p className={'lead'}>{text}</p>
      {buttonText && (
        <button className="btn btn-outline-light" type="button">
          {buttonText}
        </button>
      )}
    </div>
  );
};
export default ContentBlock;
