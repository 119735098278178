import React from 'react';

const Reviews = ({props}) => {
    return(
        <section id="carousel" className={'reviews hero_bg mb-5'}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 m-lg-auto">
                        <div className="quote">
                            <i className="bi bi-chat-square-quote-fill flex-shrink-0" style={{fontSize: '4rem', width:"1.75em", height:"1.75em", color: '#fccd39'}} role="img" aria-label="icon"/></div>
                        <div className="carousel slide" id="fade-quote-carousel" data-ride="carousel"
                             data-interval="3000">

                            <ol className="carousel-indicators">
                                <button type="button" data-bs-target="#fade-quote-carousel" data-slide-to="0" className="active"/>
                                <button type="button" data-bs-target="#fade-quote-carousel" data-slide-to="1"/>
                            </ol>

                            <div className="carousel-inner">
                                <div className="carousel-item active lead">
                                    <p className="fw-bold text-center">John Smith</p>
                                    <blockquote>
                                        <p>Brighter day cash back is a great responsive service ! Highly Recommended!</p>
                                    </blockquote>
                                </div>
                                <div className="carousel-item  lead">
                                    <p className="fw-bold text-center">Client Name</p>
                                    <blockquote>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, veritatis
                                            nulla eum laudantium totam tempore optio doloremque laboriosam quas, quos
                                            eaque molestias odio aut eius animi. Impedit temporibus nisi accusamus.</p>
                                    </blockquote>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Reviews;